<template>
  <template v-if="loading">
    <ProgressSpinner />
  </template>

  <!--Inscription externe  -->

  <template v-else-if="this.spectacle.type === 'Inscription interne'">
    <div class="container">
      <div class="col lg:col-6 md:col-12 layout-wrapper">
        <div class="layout-main-container container-spec-register">
          <div class="card card-spectacle">
            <div class="image-content">
              <img
                :src="'data:image/jpeg;base64,' + spectacle.picture.content"
              />
            </div>
            <div style="margin-top: 20px" />
            <div class="content-container">
              <div class="p-fluid grid">
                <div class="col-12">
                  <div class="alert alert-danger">
                    Attention, chaque inscription génère une facture. Il est
                    recommandé de faire une inscription groupée par école en
                    ajoutant toutes les classes participantes.
                  </div>
                </div>
              </div>

              <div class="p-fluid grid">
                <div class="col-12">
                  <h2 class="roboto-slab">
                    Inscription au spectacle - {{ this.spectacle.name }}
                  </h2>
                </div>
              </div>
              <div class="p-fluid grid">
                <div class="col-7">
                  <div class="roboto-slab">
                    <h5>
                      📍{{ this.spectacle.salle.zipCode }}
                      {{ this.spectacle.salle.city }} :
                      {{ this.spectacle.salle.name }},
                      {{ this.spectacle.salle.address }}
                    </h5>
                  </div>
                </div>
                <div class="col-5">
                  <div class="p-fluid grid float-right">
                    <h5 class="roboto-slab col-12">
                      📅 {{ formatDate(this.spectacle.dateSpectacle) }}
                    </h5>
                  </div>
                  <div class="p-fluid grid float-right">
                    <h5 class="roboto-slab col-12">
                      🕘 {{ formatTime(this.spectacle.dateSpectacle) }}
                    </h5>
                  </div>
                </div>
              </div>
              <form
                v-on:submit.prevent="createSpectacleReservation"
                ref="spectacleReservationForm"
                v-if="!finished"
              >
                <div class="p-fluid grid">
                  <div class="col-12 md:col-6">
                    <div class="field">
                      <ak-input-text
                        class="roboto-slab"
                        required="true"
                        v-model="this.reservation.name"
                        label="Nom de l'établissement"
                        :validator="v$.reservation.name"
                        :submitted="this.submitted"
                      ></ak-input-text>
                    </div>
                  </div>
                  <div class="col-12 md:col-6">
                    <div class="field">
                      <ak-input-text
                        class="roboto-slab"
                        required="true"
                        v-model="this.reservation.city"
                        label="Ville de l'établissement"
                        :validator="v$.reservation.city"
                        :submitted="this.submitted"
                      ></ak-input-text>
                    </div>
                  </div>
                  <div class="col-12 md:col-6">
                    <div class="field">
                      <ak-input-text
                        class="roboto-slab"
                        required="true"
                        v-model="this.reservation.email"
                        label="Adresse e-mail de l'établissement"
                        :validator="v$.reservation.email"
                        :submitted="this.submitted"
                      ></ak-input-text>
                    </div>
                  </div>
                  <div class="col-12 md:col-6">
                    <div class="field">
                      <ak-input-number
                        class="roboto-slab"
                        required="true"
                        v-model="this.reservation.companions"
                        label="Nombre d'accompagnants au total (enseignants compris)"
                        :validator="v$.reservation.companions"
                        :submitted="this.submitted"
                      ></ak-input-number>
                    </div>
                  </div>
                  <div class="col-12 md:col-6">
                    <div class="field">
                      <ak-input-number
                        class="roboto-slab"
                        required="true"
                        v-model="this.reservation.aesh"
                        label="Dont AESH (Accompagnant d'élève en situation de handicap)"
                        :validator="v$.reservation.aesh"
                        :submitted="this.submitted"
                      ></ak-input-number>
                    </div>
                  </div>
                  <div class="col-12 md:col-6">
                    <div class="field">
                      <ak-input-text
                        class="roboto-slab"
                        required="true"
                        v-model="this.reservation.responsible"
                        label="Nom du demandeur de la réservation"
                        :validator="v$.reservation.responsible"
                        :submitted="this.submitted"
                      ></ak-input-text>
                    </div>
                  </div>

                  <div class="col-12 md:col-6">
                    <div class="field">
                      <ak-input-text
                        class="roboto-slab"
                        required="true"
                        v-model="this.reservation.emailRequester"
                        label="Adresse e-mail du demandeur de la réservation"
                        :validator="v$.reservation.emailRequester"
                        :submitted="this.submitted"
                      ></ak-input-text>
                    </div>
                  </div>

                  <div class="col-12 md:col-6">
                    <div class="field">
                      <ak-input-text
                        class="roboto-slab"
                        required="true"
                        v-model="this.reservation.phone"
                        label="Numéro de téléphone à contacter "
                        :validator="v$.reservation.phone"
                        :submitted="this.submitted"
                      ></ak-input-text>
                    </div>
                  </div>

                  <div class="col-12">
                    <DataTable
                      :value="this.grades"
                      :loading="this.loading"
                      currentPageReportTemplate="{first} à {last} des {totalRecords} spectacles"
                      responsiveLayout="scroll"
                    >
                      <template #header>
                        <div
                          class="flex flex-column md:flex-row md:justify-content-between md:align-items-center"
                        >
                          <h4 class="m-0 col-8 roboto-slab">
                            Liste des classes
                          </h4>
                          <Button
                            @click="createGradeDialog = true"
                            class="jm-red col-4"
                            label="Ajouter une classe"
                            icon="pi pi-plus"
                          />
                        </div>
                      </template>
                      <template #empty>
                        <div class="roboto-slab">Aucune classe créée</div>
                      </template>
                      <Column
                        class="roboto-slab"
                        field="name"
                        header="Nom de la classe"
                        :sortable="true"
                      ></Column>
                      <Column
                        class="roboto-slab"
                        field="number"
                        header="Nombre d'élèves"
                        :sortable="true"
                      ></Column>
                      <Column style="text-align: right">
                        <template #body="slotProps">
                          <Button
                            @click="deleteGrade(slotProps)"
                            icon="pi pi-trash"
                            class="p-button-rounded p-button-danger mr-2 mb-2 jm-red"
                          />
                        </template>
                      </Column>
                    </DataTable>
                  </div>
                </div>
                <Button
                  @click="checkForm()"
                  class="jm-red float-right mt-2 mb-2"
                  icon="pi pi-check"
                  label="Valider la reservation"
                ></Button>
              </form>

              <template v-if="finished">
                <hr />
                <div class="p-fluid grid mt-5 mb-3">
                  <div class="col-12">
                    <div class="field">
                      <h4 style="color: red">
                        Votre demande de reservation a bien été envoyée. Vous
                        allez recevoir un email récapitulatif à l'adresse :
                        {{ this.reservation.email }}
                      </h4>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Dialog
      v-model:visible="createGradeDialog"
      :style="{ width: '500px' }"
      header="Créer une classe"
      :modal="true"
    >
      <div class="p-fluid grid">
        <div class="col-12 md:col-6">
          <ak-input-text
            required="true"
            v-model="this.grade.name"
            :validator="v$.grade.name"
            label="Classe"
            :submitted="this.createPopupSubmitted"
          ></ak-input-text>
        </div>
        <div class="col-6">
          <ak-input-number
            required="true"
            v-model="this.grade.number"
            :validator="v$.grade.number"
            label="Nombre d'élèves"
            :submitted="this.createPopupSubmitted"
          ></ak-input-number>
        </div>
      </div>
      <div class="p-dialog-footer">
        <Button
          label="Annuler"
          icon="pi pi-times"
          class="p-button-text jm-red-color"
          @click="createGradeDialog = false"
        />
        <Button
          label="Valider"
          icon="pi pi-check"
          class="p-button-text jm-red-color"
          @click="createGrade"
        />
      </div>
    </Dialog>

    <Dialog
      v-model:visible="confirmDataDialog"
      :style="{ width: '500px' }"
      header="Valider la réservation"
      :modal="true"
    >
      <div class="p-fluid grid">
        <div class="col-12">
          <div class="field">
            <p class="roboto-slab">
              La réservation est sur le point d'être créée. Veuillez confirmer
              les informations suivantes :
            </p>
          </div>
        </div>
        <div class="col-12">
          <div class="field">
            <ak-input-text
              disabled
              class="roboto-slab"
              required="true"
              v-model="this.reservation.name"
              label="Nom de l'établissement"
              :validator="v$.reservation.name"
              :submitted="this.submitted"
            ></ak-input-text>
          </div>
        </div>
        <div class="col-12">
          <div class="field">
            <ak-input-text
              disabled
              class="roboto-slab"
              required="true"
              v-model="this.reservation.city"
              label="Ville de l'établissement"
              :validator="v$.reservation.city"
              :submitted="this.submitted"
            ></ak-input-text>
          </div>
        </div>
        <div class="col-12">
          <div class="field">
            <ak-input-text
              disabled
              class="roboto-slab"
              required="true"
              v-model="this.reservation.email"
              label="Adresse e-mail de l'établissement"
              :validator="v$.reservation.email"
              :submitted="this.submitted"
            ></ak-input-text>
          </div>
        </div>
        <div class="col-12">
          <div class="field">
            <ak-input-text
              disabled
              class="roboto-slab"
              required="true"
              v-model="this.reservation.emailRequester"
              label="Adresse e-mail du demandeur de la réservation"
              :validator="v$.reservation.emailRequester"
              :submitted="this.submitted"
            ></ak-input-text>
          </div>
        </div>
      </div>
      <div class="p-dialog-footer">
        <Button
          label="Retour à l'édition"
          icon="pi pi-times"
          class="p-button-text jm-red-color"
          @click="confirmDataDialog = false"
        />
        <Button
          label="Valider"
          icon="pi pi-check"
          class="jm-red"
          @click="
            createSpectacleReservation();
            confirmDataDialog = false;
          "
        />
      </div>
    </Dialog>
  </template>

  <template v-else>
    <div class="container">
      <div class="col lg:col-6 md:col-12 layout-wrapper">
        <div class="layout-main-container container-spec-register">
          <div class="card card-spectacle">
            <div class="image-content">
              <img
                :src="'data:image/jpeg;base64,' + spectacle.picture.content"
              />
            </div>
            <div style="margin-top: 20px"></div>
            <div class="content-container">
              <div class="p-fluid grid">
                <div class="col-12">
                  <h2 class="roboto-slab">
                    Inscription au spectacle - {{ this.spectacle.name }}
                  </h2>
                </div>
              </div>

              <div class="p-fluid grid">
                <div class="col-7">
                  <div class="roboto-slab">
                    <h5>📍 Salle : {{ this.spectacle.salle.name }}</h5>
                  </div>
                  <div class="roboto-slab">
                    <h5>
                      🗺️ {{ this.spectacle.salle.address }},
                      {{ this.spectacle.salle.zipcode }},
                      {{ this.spectacle.salle.city }}
                    </h5>
                  </div>
                  <div class="roboto-slab" style="margin-top: 40px">
                    <h5 for="inscription" style="font-family: 'Roboto Slab'">
                      Détails d'inscription :
                    </h5>
                  </div>
                </div>

                <div class="col-5">
                  <div class="p-fluid grid float-right">
                    <h5 class="roboto-slab col-12">
                      📅 {{ formatDate(this.spectacle.dateSpectacle) }}
                    </h5>
                  </div>
                  <div class="p-fluid grid float-right">
                    <h5 class="roboto-slab col-12">
                      🕘 {{ formatTime(this.spectacle.dateSpectacle) }}
                    </h5>
                  </div>
                </div>
              </div>
              <div class="p-fluid grid">
                <ck-editor
                  id="inscription"
                  v-model="this.spectacle.sessionInscription"
                  className="ck-readonly"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import SpectacleService from "@/service/SpectacleService";
import randomRef from "@mixins/randomRef";
import useVuelidate from "@vuelidate/core";
import { email, required } from "@vuelidate/validators";
import AkInputText from "@/components/input/AkInputText";
import AkInputNumber from "@/components/input/AkInputNumber";
import GradeService from "@/service/GradeService";
import SpectacleReservationService from "@/service/SpectacleReservationService";
import { formatDate, formatTime } from "/src/utils/utils.js";
import DateSpectacleService from "../../service/DateSpectacleService";
import CkEditor from "@components/CkEditor.vue";

export default {
  name: "SpectacleRegister",

  components: { AkInputText, AkInputNumber, CkEditor },

  mixins: [randomRef],

  setup() {
    return { v$: useVuelidate() };
  },

  validations() {
    return {
      reservation: {
        name: { required },
        city: { required },
        email: { required, email },
        companions: { required },
        responsible: { required },
        emailRequester: {},
        phone: { required },
        freeGuide: {},
        aesh: { required },
      },
      grade: {
        number: { required },
        name: { required },
      },
    };
  },

  data() {
    return {
      spectacle: null,
      loading: true,
      submitted: false,
      createPopupSubmitted: false,
      reservation: {},
      createGradeDialog: false,
      grades: [],
      grade: {},
      finished: false,
      confirmDataDialog: false,
    };
  },

  spectacleService: null,
  dateSpectacleService: null,
  gradeService: null,
  spectacleReservationService: null,
  created() {
    this.spectacleService = new SpectacleService();
    this.gradeService = new GradeService();
    this.spectacleReservationService = new SpectacleReservationService();
    this.dateSpectacleService = new DateSpectacleService();
  },

  methods: {
    formatDate,
    formatTime,
    checkForm() {
      this.submitted = true;
      this.v$.reservation.$touch();
      if (this.v$.reservation.$error) return;
      this.confirmDataDialog = true;
    },
    createSpectacleReservation() {
      this.submitted = true;

      this.v$.reservation.$touch();
      if (this.v$.reservation.$error) return;
      this.reservation.spectacleId = this.spectacle.spectacleId;
      this.reservation.grades = this.grades;
      let reservationDto = {
        spectacleReservation: this.reservation,
        grades: this.grades,
      };
      reservationDto.spectacleReservation.dateSpectacleId =
        this.$route.params.dateSpectacleId;
      this.spectacleReservationService.create(reservationDto).then((data) => {
        this.reservation = data;
      });

      this.finished = true;
    },
    createGrade() {
      this.createPopupSubmitted = true;
      this.v$.grade.$touch();
      if (this.v$.grade.$error) return;
      this.grades.push(this.grade);
      this.grade = {};
      this.createGradeDialog = false;
      this.createPopupSubmitted = false;
    },
    deleteGrade(index) {
      this.grades.splice(index);
    },
  },

  mounted() {
    let p1 = this.spectacleService.spectacle(this.$route.params.spectacleId);
    let p2 = this.dateSpectacleService.dateSpectacle(
      this.$route.params.dateSpectacleId
    );
    Promise.all([p1, p2]).then((values) => {
      this.spectacle = values[0];
      this.spectacle.dateSpectacle = values[1].dateSpectacle;
      this.loading = false;
    });
  },
};
</script>

<style lang="scss">
body {
  background-color: #f0f1e9;
}

.image-content {
  img {
    width: 100%;
  }
}
</style>
